/* Default mobile styles */
.desktop-text {
  display: none;
}

/* Desktop styles */
@media (min-width: 768px) {
  /* Adjust this breakpoint as necessary */
  .desktop-text {
    display: inline;
  }
}
