.scene {
  /* Both are set in Metrics.tsx */
  /* --cube-width: 100%; */
  /* --cube-height: 100%;  */
  --cube-translate: calc(var(--cube-width) / 2);
}

.scene {
  width: var(--cube-width);
  height: var(--cube-height);
  perspective: 400px;
}

.box {
  width: var(--cube-width);
  height: var(--cube-height);
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-50px);
  transition: transform 1s;
}

.box.show-front {
  transform: translateZ(calc(var(--cube-translate) * -1)) rotateY(0deg);
}
.box.show-back {
  transform: translateZ(calc(var(--cube-translate) * -1)) rotateY(-180deg);
}
.box.show-right {
  transform: translateZ(calc(var(--cube-translate) * -1)) rotateY(-90deg);
}
.box.show-left {
  transform: translateZ(calc(var(--cube-translate) * -1)) rotateY(90deg);
}
.box.show-top {
  transform: translateZ(calc(var(--cube-translate) * -1)) rotateX(-90deg);
}
.box.show-bottom {
  transform: translateZ(calc(var(--cube-translate) * -1)) rotateX(90deg);
}

.box__face {
  position: absolute;
}

.box__face--front,
.box__face--back {
  width: var(--cube-width);
  height: var(--cube-height);
}

.box__face--right,
.box__face--left {
  width: var(--cube-width);
  height: var(--cube-height);
}

.box__face--top,
.box__face--bottom {
  width: var(--cube-width);
  height: var(--cube-width);
}

.box__face--front {
  transform: rotateY(0deg) translateZ(var(--cube-translate));
}
.box__face--back {
  transform: rotateY(180deg) translateZ(var(--cube-translate));
}

.box__face--right {
  transform: rotateY(90deg) translateZ(var(--cube-translate));
}
.box__face--left {
  transform: rotateY(-90deg) translateZ(var(--cube-translate));
}

.box__face--top {
  transform: rotateX(90deg) translateZ(var(--cube-translate));
}
.box__face--bottom {
  transform: rotateX(-90deg) translateZ(-60px);
}
