.RainbowText {
  background: linear-gradient(
      90.31deg,
      #5c9af6 5.88%,
      #00bb56 25.79%,
      #f34242 75.75%,
      #ff7612 99.88%
    ),
    linear-gradient(89.98deg, #5c9af6 5.23%, #f34242 25.35%, #ff7612 89.93%, #00bb56 99.99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
